@import 'assets/styles/Constants.scss';
@import 'assets/styles/Media.scss';

.container {
  padding: 40px 20px;
  background-color: #F8F8F8;
  border: 1px solid var(--colorGray);
}

.messageContainer {
  .image {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  @media screen and (min-width: $phone-sm) {
    .image {
      height: 290px;

      svg {
        width: 150px;
        height: 150px;
      }
    }
  }

  .success {
    background-color: var(--colorGold);
  }

  .error {
    background-color: var(--colorBlue);
  }

  .textContainer {
    padding: 40px 20px;
    background-color: #fff;
    border-left: 1px solid var(--colorGray);
    border-right: 1px solid var(--colorGray);
    border-bottom: 1px solid var(--colorGray);
    text-align: center;

    .title {
      margin-bottom: 25px;
      font-size: 24px;
      font-family: var(--primaryFont);
      color: var(--colorBlue);
    }

    .message {
      font-size: 16px;
    }

    @media screen and (min-width: $phone-sm) {
      padding: 60px 30px;

      .title {
        margin-bottom: 40px;
        font-size: 44px;
      }
    }
  }

  :global(.button) {
    width: 100%;
    margin-top: 20px;
  }
}

@import './Constants.scss';
@import './Media.scss';

body {
  padding: 0;
  margin: 0;
  border: none;
  color: var(--colorBlack);
  font-family: var(--secondaryFont);
  font-size: 14px;
  line-height: 1.2;

  @media screen and (min-width: $phone-sm) {
    font-size: 16px;
  }
}

.ant-collapse {
  color: var(--colorBlack);
  font-family: var(--secondaryFont);
  font-size: 14px;

  @media screen and (min-width: $phone-sm) {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--primaryFont);
  }
}

.ant-collapse p {
  font-size: 14px;
  line-height: 1.75;

  @media screen and (min-width: $phone-sm) {
    font-size: 16px;
  }
}


h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  font-family: var(--primaryFont);
}

P {
  padding: 0;
  margin: 0;
  line-height: 1.75;
}

a {
  color: var(--colorBlack);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
  }
}

.main-container {
  max-width: 1920px;
  margin: 0 auto;
}

.inner-container {
  max-width: 1200px;
  margin: auto;
}

.header {
  padding: 15px;
  background-color: var(--colorBlue);

  .inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .phone {
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;

    a {
      color: #FFFFFF;
      text-decoration: none;
      font-weight: 400;
    }
  }
}

.footer {
  margin-top: 20px;
  padding: 40px 15px;
  background-color: var(--colorBlue);

  .inner-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    
    color: #fff;
    font-size: 14px;
    
    label {
      display: block;
      margin-bottom: 10px;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  @media screen and (min-width: $phone-sm) {
    margin-top: 40px;

    .footer-container {
      flex-direction: row;
      align-items: end;
      justify-content: space-between;
    }
  }
}

.navigation-menu {
  .inner-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-family: var(--primaryFont);
    transition: all 0.2s;
  }

  .name {
    font-weight: 500;
    font-size: 27px;
    white-space: nowrap;
    line-height: 1;
    color: var(--colorBlue);
  }

  .menu {
    display: flex;
    gap: 20px;
    font-size: 16px;

    a {
      color: var(--colorBlue);
      text-decoration: none;
      font-weight: 400;
      text-align: center;
    }
  }

  @media screen and (min-width: $tablet) {
    .inner-container {
      padding: 30px 15px;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      gap: 0;
    }

    .name {
      font-size: 37px;
    }

    .menu {
      font-size: 26px;
      gap: 60px;
    }
  }
}

.hero-banner {
  padding: 30px 15px;
  display: flex;
  background-image: url('../../assets/images/hero-banner.png');
  background-size: cover;
  background-position: center;
  font-family: var(--primaryFont);

  .inner-container {
    max-width: 260px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    transition: max-width 0.2s;
  }

  .hero-text {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #fff;
  }

  @media screen and (min-width: $phone-sm) {
    height: 500px;
    padding: 0;

    .inner-container {
      max-width: 600px;
      gap: 50px;
    }

    .hero-text {
      font-size: 44px;
      line-height: 60px;
    }
  }

  @media screen and (min-width: $tablet) {
    // height: 754px;
  }

}

.button {
  padding: 8px 18px;
  border: none;
  height: auto;
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  font-family: var(--primaryFont);
  font-weight: 700;
  line-height: 16px;
  color: #000;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  text-align: center;
  text-decoration: none;

  &.gold,
  &.gold:hover {
    background-color: var(--colorGold)!important;
    color: #000!important;
  }

  &.blue,
  &.blue:hover {
    background-color: var(--colorBlue)!important;
    color: #fff!important;
  }

  &:active {
    transform: scale(0.95);
    background-color: darkgray;
  }

  @media screen and (min-width: $phone-sm) {
    font-size: 18px;
    line-height: 37px;
    min-width: 254px;
  }

  @media screen and (min-width: $tablet) {
    font-size: 20px;
  }
}

.biography-container {
  padding: 30px 15px;

  .inner-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .image-container {
    text-align: center;

    img {
      width: 100%;
      max-width: 500px;
      height: auto;
      display: block;
      transition: width 0.2s;
    }
  }

  .biography-name {
    font-size: 18px;
    font-weight: 700;
    color: var(--colorBlue);
  }

  @media screen and (min-width: $phone-sm) {
    padding: 60px 15px;

    .inner-container {
      flex-direction: row;
      gap: 30px;
      margin-bottom: 30px;
    }

    .image-container {
      img {
        width: 260px;
      }
    }

    .biography-name {
      font-size: 44px;
      font-weight: 400;
      line-height: 60px;
    }
  }

  @media screen and (min-width: $tablet) {
    padding: 100px 15px;

    .image-container {
      img {
        width: 500px;
      }
    }

    .biography-name {
      margin-bottom: 20px;
    }
  }
}

.how-can-help {
  padding: 30px 15px;
  background-image: url('../../assets/images/how-can-i-help.png');
  background-size: cover;
  background-position: center;
  color: #fff;
  text-align: center;

  h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
  }

  .separator-container {
    margin-bottom: 25px;
  }

  .inner-container {
    max-width: 580px;
  }

  @media screen and (min-width: $phone-sm) {
    padding: 60px 15px;

    h3 {
      font-size: 36px;
      line-height: 60px;
    }

    .separator-container {
      margin-bottom: 40px;
    }
  }

  @media screen and (min-width: $tablet) {
    padding: 80px 15px;

    .separator-container {
      margin-bottom: 60px;
    }
  }
}

.collapse-menu {
  padding: 30px 15px 15px;

  .ant-collapse {
    margin-top: 30px;
    border: none;
    border-radius: 0;

    > .ant-collapse-item {
      border: none;

      &:last-child {
        border-radius: 0;

        .ant-collapse-header {
          border-radius: 0;
        }
      }
    }

    .ant-collapse-header {
      padding: 12px 10px;
      background-color: #ECECF2;
      border-bottom: 1px solid var(--colorGray);
      font-family: var(--primaryFont);
      font-size: 18px;
      line-height: 1.3;
      color: var(--colorBlue);
      font-weight: 700;

      .ant-collapse-expand-icon {
        svg {
          width: 12px;
          height: 10px;
        }
      }
    }

    .ant-collapse-content {
      border: none;
    }

    .ant-collapse-content-box {
      padding: 20px 10px;
    }
  }

  .ant-collapse-content-box {
    > * {
      margin-bottom: 15px;
    }

    h3 {
      font-size: 18px;
      font-weight: 400;
      color: var(--colorBlue);
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      color: var(--colorBlue);
      text-align: center;
    }

    ul, ol {
      margin-top: 0;
      padding-left: 14px;

      li {
        margin-bottom: 15px;
      }
    }

    strong {
      color: var(--colorBlue);
    }

    p, li {
      white-space: pre-line;
    }

    @media screen and (min-width: $phone-sm) {
      > * {
        margin-bottom: 25px;
      }

      h3 {
        font-size: 26px;
      }

      h4 {
        font-size: 20px;
      }
    }

    @media screen and (min-width: $tablet) {
      > * {
        margin-bottom: 30px;
      }

      h3 {
        font-size: 44px;
      }

      h4 {
        font-size: 36px;
      }
    }
  }

  @media screen and (min-width: $phone-sm) {
    padding: 60px 15px;

    .ant-collapse {
      margin-top: 60px;

      .ant-collapse-header {
        padding: 20px 15px;
        font-size: 20px;

        .ant-collapse-expand-icon {
          svg {
            width: 22px;
            height: 18px;
          }
        }
      }

      .ant-collapse-content-box {
        padding: 40px 15px;
      }
    }
  }
}

.collapse-visa-container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  > div {
    padding: 20px;
    position: relative;
    border: 1px solid var(--colorGray);
    overflow: hidden;
    z-index: 5;

    &:nth-child(1) {
      &::after {
        content: '1';
      }
    }

    &:nth-child(2) {
      &::after {
        content: '2';
      }
    }

    &:nth-child(3) {
      &::after {
        content: '3';
      }
    }

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      font-family: var(--primaryFont);
      font-weight: 700;
      line-height: 1;
      color: var(--colorLightGray);
      font-size: 300px;
      z-index: -1;
    }
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-weight: var(--primaryFont)!important;
    font-weight: 700;
    color: var(--colorGold);
  }

  h5 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    color: var(--colorBlue);
  }

  @media screen and (min-width: $phone-sm) {
    gap: 25px;

    label {
      font-size: 20px;
    }

    h5 {
      font-size: 26px;
    }
  }

  @media screen and (min-width: $tablet) {
    flex-direction: row;
    gap: 35px;

    > div {
      width: calc(100% / 3);
    }
  }
}

.collapse-styled-list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .item {
    padding: 20px;
    position: relative;
    border: 1px solid var(--colorGray);

    .title-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      gap: 15px;

      .text {
        &.title {
          font-family: var(--primaryFont);
          font-size: 16px;
          font-weight: 400;
          color: var(--colorBlue);
        }
      }
    }

    .counter {
      width: 30px;
      height: 30px;
      border: 1px solid var(--colorGold);
      border-radius: 50%;
      text-align: center;
      line-height: 22.5px;
      font-family: var(--primaryFont);
      font-size: 24px;
      color: var(--colorBlue);
      flex-shrink: 0;
    }

    .content-container {
      margin-top: 15px;

      > * {
        margin-bottom: 15px;
      }
    }
  }

  @media screen and (min-width: $phone-sm) {
    .item {
      .counter {
        width: 60px;
        height: 60px;
        line-height: 45px;
        font-size: 44px;
      }

      .title-container {
        .text {
          &.title {
            font-size: 26px;
          }
        }
      }
    }
  }
}

.service-container {
  margin-bottom: 30px;

  @media screen and (min-width: $phone-sm) {
    margin-bottom: 60px;
  }
}

.contact-us-container {
  .inner-container {
    padding: 0 15px 15px;
  }

  .page-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 400;
    color: var(--colorBlue);
    text-align: center;
  }

  .content-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;

    @media screen and (min-width: $tablet) {
      gap: 60px;
      flex-direction: row;

      > * {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: $phone-sm) {
    .page-title {
      margin-bottom: 60px;
      font-size: 44px;
    }
  }
}

.content-details-list {
  margin-top: 20px;

  .item {
    display: flex;
    align-items: baseline;
    gap: 15px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .title {
    font-family: var(--primaryFont);
    font-size: 16px;
    font-weight: 400;
    color: #595959;
  }

  .counter {
    width: 30px;
    height: 30px;
    border: 1px solid var(--colorGold);
    border-radius: 50%;
    text-align: center;
    line-height: 22.5px;
    font-family: var(--primaryFont);
    font-size: 24px;
    color: var(--colorBlue);
    flex-shrink: 0;
  }

  @media screen and (min-width: $tablet) {
    .item {
      gap: 30px;
    }

    .title {
      font-size: 26px;
    }

    .counter {
      width: 48px;
      height: 48px;
      font-size: 36px;
      line-height: 34px;
    }
  }
}

.contact-us-container {
  .ant-col {
    display: flex !important;
    flex-direction: column;
    justify-content: end;
  }
  
  .ant-form-item {
    width: 100%;
    margin: 0;
  }

  .ant-picker {
    width: 100%;
  
    input {
      font-family: var(--secondaryFont)!important;
      font-size: 16px!important;
      color: var(--colorDarkGray)!important;
  
      &::placeholder {
        font-family: var(--secondaryFont)!important;
        font-size: 16px!important;
        color: var(--colorDarkGray)!important;
      }
    }
  }

  .ant-radio-group {
    height: 44px;
    display: flex;
    align-items: center;
  }
  
  .ant-select-single {
    height: 44px;
    font-family: var(--secondaryFont);
    font-size: 16px;
    color: var(--colorDarkGray)!important;
  
    .ant-select-selector {
      border-radius: 0;
    }
  }

  .ant-input,
  .ant-picker {
    height: 44px;
    border-radius: 0;
    font-family: var(--secondaryFont);
    font-size: 16px;
    color: var(--colorDarkGray)!important;

    &::placeholder {
      color: var(--colorDarkGray);
    }
  }

  textarea.ant-input {
    height: auto;
  }
}

.contact-us-button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
  margin-top: 20px;

  .ant-btn {
    width: 50%;
  }
}


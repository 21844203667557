.flagContainer {
  display: flex;
  justify-content: space-around;
  gap: 30px;
}

.flagIcon {
  cursor: pointer;
  display: flex;

  svg {
    width: 24px;
  }

  &.active {
    box-shadow: 0px 0px 20px #fff;
  }
}

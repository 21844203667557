@import 'assets/styles/Constants.scss';
@import 'assets/styles/Media.scss';

.container {
  width: 100px;
  height: 1px;
  margin: 4px auto;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 35px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
  }

  &::before {
    top: -4px;
  }

  &::after {
    bottom: -4px;
  }

  &.gold {
    background-color: var(--colorGold);

    &::before,
    &::after {
      background-color: var(--colorGold);
    }
  }

  &.blue {
    background-color: var(--colorBlue);

    &::before,
    &::after {
      background-color: var(--colorBlue);
    }
  }

  @media screen and (min-width: $phone-sm) {
    width: 350px;
    margin: 8px auto;

    &::before,
    &::after {
      width: 130px;
    }

    &::before {
      top: -8px;
    }

    &::after {
      bottom: -8px;
    }
  }
}

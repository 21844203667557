:root {
  --primaryFont: 'Playfair Display', serif;
  --secondaryFont: 'Work Sans', sans-serif;

  --colorBlue: #0E2D55;
  --colorGold: #DBBF92;
  --colorGray: #D5D6DA;
  --colorDarkGray: #818286;
  --colorLightGray: #F8F8F8;
  --colorBlack: #141415;
}
